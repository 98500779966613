import React from 'react'

const CardCheckins = () => {
  return (
    <div className="preventive-checkin-content">
      <h4>4 annual check-ins</h4>
      <p>Keep up the good work. 
      Continously work to strengthen your relation with scheduled check-ins</p>
     </div>
  )
}

export default CardCheckins